@import url('https://fonts.googleapis.com/css?family=PT+Sans:400,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Lato&display=swap');

html{
    height: 100%;
}

.car-model-features:odd{
  font-weight: bold;
}

.dark-red{
  background: #B21606 !important;
  color: #ffffff !important;
}

ul {
  list-style: none;
}

.caret {
  -moz-transition: transform 1s;
  -webkit-transition: transform 1s;
  transition: transform 1s;
}

.rotate-180 {
  transform: rotate(-180deg);
}

body{
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    -webkit-flex-direction: column;
    height: 100%;
    margin: 0;
    font-family: 'PT Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1{
  font-family: 'PT Sans', sans-serif;
  font-weight: 800;
  font-size: 3em;
  text-align: center;
}

h2{
  font-family: 'PT Sans', sans-serif;
  font-weight: 700;
  text-align: center;
}

p{
  font-family: 'Lato', sans-serif;
  font-size: 1.4em;
  spacing: 10px;
}

.form-label{
  font-size: 1.1em;
}
.main-container{
  margin-top: 30px;
}

.main-container{
  min-height: 700px;
}

.spinner-border{
  position: relative;
  left: 50%;
  margin-left: -4em;
  margin-top: 330px;
}

.sr-only
{
  left: 50%;
  margin-left: -4em;
}

.jumbotron{
  padding: 5rem 1rem !important;
  margin-bottom: 0 !important;
  min-height: 730px;
  border-bottom: 3px ridge #B21606;
  background-size: cover;
}

.jumbotron > h1{
  text-align: center;
  width: auto;
  text-shadow: 0 0 2px #B21606;
}

.facebook-button{
  border: 2px solid #ffffff;
}

.facebook-button:hover{
  drop-shadow: 0 0 2px #fff;
}

.demo-bg{
background: #ffac0c;
margin-top: 60px;
}
.business-hours {
background: #222;
padding: 40px 14px;
margin-top: -15px;
position: relative;
}
.business-hours:before{
content: '';
width: 23px;
height: 23px;
background: #111;
position: absolute;
top: 5px;
left: -12px;
transform: rotate(-45deg);
z-index: -1;
}
.business-hours .title {
font-size: 20px;
color: #BBB;
text-transform: uppercase;
padding-left: 5px;
border-left: 4px solid #ffac0c;
}
.business-hours li {
color: #888;
line-height: 30px;
border-bottom: 1px solid #333;
}
.business-hours li:last-child {
border-bottom: none;
}
.business-hours .opening-hours li.today {
color: #ffac0c;
}




.carousel-caption > p{
  font-size: 1.8em;
  text-shadow: 0 0 2px black;

}
.carousel-caption > h1{
  font-size: 2.6em;
  text-shadow: 0 0 2px black;
  padding-bottom: 300px;

}
.carousel-item
{
  color: #fff;
}
.page-header{
  text-align: center;
  display: block;
  width: 100%;
  font-size: 2.2em;
  padding: 0 0 30px;
  text-transform: uppercase;
  font-weight: 600;
}

.content{
   flex: 1 0 auto;
   -webkit-flex: 1 0 auto;
   min-height: 200px;
}

.car-title-row{
  margin-top: -25px;
}

.car-header
{
  text-align: center;
  font-family: 'PT Sans', sans-serif;
  font-weight: 700;
  margin: 20px auto !important;
}

.car-modal
{
  width: 80vw !important;
  max-width: 80vw !important;
}

.car-modal-details
{
  display: block;
  text-align: center;
  font-size: 1.4em;
}

.car-modal-details-icon
{
  width: 1.2em;
  display: inline-block !important;
}

.car-info-modal-details-icon
{
  max-width: 100%;
  width: 1.4em;
  display: inline-block !important;
}

.car-modal-details-features
{
  font-size: 1.2em;
}

.car-modal-details-value
{
  text-transform: capitalize;
  display: inline-block;
  font-size: 1em;
}

.car-modal-description
{
  font-size: 1.2em;
  margin: 20px auto !important;
  text-align: justify;
}

#car-modal-title{
  display: block;
  font-size: 2em;
  width: 100%;
  margin: 10px auto 0;
  text-align: center;
}

.accordion > div.card:first-of-type {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
  border-radius: calc(0.25rem - 1px);
}

.accordion > div.card:hover {
  cursor: pointer;
}

#ads {
    margin: 30px 0 30px 0;

}

#ads .card-notify-badge {
        position: absolute;
        left: -10px;
        top: -20px;
        background: #f2d900;
        text-align: center;
        border-radius: 30px 30px 30px 30px;
        color: #000;
        padding: 5px 10px;
        font-size: 14px;

    }

#ads .card-notify-year {
        position: absolute;
        right: -10px;
        top: -20px;
        background: #ff4444;
        border-radius: 50%;
        text-align: center;
        color: #fff;
        font-size: 14px;
        width: 50px;
        height: 50px;
        padding: 15px 0 0 0;
}


#ads .card-detail-badge {
        background: #f2d900;
        text-align: center;
        border-radius: 30px 30px 30px 30px;
        color: #000;
        padding: 5px 10px;
        font-size: 14px;
    }



#ads .card:hover {
            background: #fff;
            box-shadow: 12px 15px 20px 0px rgba(46,61,73,0.15);
            border-radius: 4px;
            transition: all 0.3s ease;
        }

#ads .card-image-overlay {
        font-size: 20px;

    }


#ads .card-image-overlay span {
            display: inline-block;
        }


#ads .ad-btn {
        text-transform: uppercase;
        width: 150px;
        height: 40px;
        border-radius: 80px;
        font-size: 16px;
        line-height: 35px;
        text-align: center;
        border: 3px solid #e6de08;
        display: block;
        text-decoration: none;
        margin: 20px auto 1px auto;
        color: #000;
        overflow: hidden;
        position: relative;
        background-color: #e6de08;
    }

#ads .ad-btn:hover {
            background-color: #e6de08;
            color: #1e1717;
            border: 2px solid #e6de08;
            background: transparent;
            transition: all 0.3s ease;
            box-shadow: 12px 15px 20px 0px rgba(46,61,73,0.15);
        }

#ads .ad-title h5 {
        text-transform: uppercase;
        font-size: 18px;
}

.card-header,
.card-footer{
  background-color: rgba(178,22,6,0.1) !important;
}


.card-text{
  font-size: 1.5em
}
.rc-slider-handle
{
  border: solid 2px #B21606 !important;
}

.rc-slider-track
{

    background-color: rgba(178,22,6,0.6) !important;
}

#navbar-container{
  background-color: white;
  border-bottom: 3px ridge #B21606;
}


.navbar-nav > a,
.navbar-nav > .nav-item,
.nav-link,
.nav-link > svg {
    font-size: 20px !important;
    color: black;
}

.navbar{
  padding: 0 1rem !important;
}

.nav-link
{
  color: black !important;
  font-size: 1.1em;
  font-weight: 800;
  padding: 0.5rem 1.5rem 0rem 1.5rem;
}


.nav-link:hover
{
  text-shadow: 0 0 1px #B21606;
}
.menu-options > .nav-link{

  margin: 0 15px;
  letter-spacing: 1px;
}

.navbar-contact-info{
  position: relative;
  box-sizing: inherit;
  display: block;
  height:60px;
}


.ribbon-top-left {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
  top: -10px;
  left: -10px;
}

.ribbon-top-left::before,
.ribbon-top-left::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid rgba(0,0,0,0.15);
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon-top-left span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 15px 0;
  background-color: #B21606;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  color: #fff;
  font: 700 18px/1 'Lato', sans-serif;
  text-shadow: 0 1px 1px rgba(0,0,0,.2);
  text-transform: uppercase;
  text-align: center;
  right: -25px;
  top: 30px;
  transform: rotate(-45deg);
}

.ribbon-top-left::before {
  top: 0;
  right: 0;
}

.ribbon-top-left::after {
  bottom: 0;
  left: 0;
}

.toggle-menu {
    background: #333;
    width: 100%;
    height: 200px;
    padding: 2em 3em;
    font-family: 'Spectral', serif;
    font-size: 1.3em;
    color: #fff;
    position: absolute;
    top: -80px;
    z-index: -1;
    transition: all .5s;
}
.toggle-menu h2{
    margin: 0 0 10px;
}
.menu-trigger{
    display: none;
}
label[for="menu-trigger"] {
    width: 120px;
    height: 30px;
    background: #1686ce;
    cursor: pointer;
    position: absolute;
    left: 0px;
    top: 120px;
    border-radius: 0px;
}
label[for="menu-trigger"]:after {
    content: '+ OPEN';
    display: block;
    position: absolute;
    color: #fff;
    left: 25%;
    top: 15%;
}
.menu-trigger:checked ~ .toggle-menu {
    top: 0;
}
.menu-trigger:checked + label[for="menu-trigger"]{
    top: 210px;
    position: absolute;
    background: #c32c2c;
    transition: all .5s;
}
.menu-trigger:checked + label[for="menu-trigger"]:after {
    content: '- CLOSE';
    display: block;
    position: absolute;
    color: #fff;
    left: 25%;
    top: 15%;
}
.menu-trigger:checked ~ .wrapper {
    top: 200px;
    margin-left: 10px;
    position: relative;
}

.home-map-container {
  height: 400px;
  margin-bottom: 50px;
}

.map-container {
  height: 600px;
  margin-bottom: 50px;
}

.contact-map > div
{
  border-bottom: 3px ridge #B21606;
}


.contact-form-container{
    margin-bottom: 50px;
}

.contact-form-header,
.contact-details-header
{
    text-align: center;
}

.contact-icon{
  margin: 15px auto;
  position: relative;
}

.service-container{
  margin: 20px auto;
  box-shadow: 1px 1px 5px black;
}

.service-image{
  width: 70%;
  display: block;
  position: relative;
  margin: 20px auto;
}
.sc-left{
  float: right;
}

.sc-right{
  float: right;
  text-align: right;
}

#myFooter {
    background-color: #3c3d41;
    color: white;
    padding-top: 30px;
}

#myFooter .footer-copyright {
    background-color: #333333;
    padding-top: 3px;
    padding-bottom: 3px;
    text-align: center;
}

#myFooter .row {
    margin-bottom: 60px;
}

#myFooter .navbar-brand {
    margin-top: 45px;
    height: 65px;
}

#myFooter .footer-copyright p {
    margin: 10px;
    color: #ccc;
}

#myFooter ul {
    list-style-type: none;
    padding-left: 0;
    line-height: 1.7;
}

#myFooter h5 {
    font-size: 18px;
    color: white;
    font-weight: bold;
    margin-top: 30px;
}

#myFooter h2 a{
    font-size: 50px;
    text-align: center;
    color: #fff;
}

#myFooter a {
    color: #d2d1d1;
    text-decoration: none;
}

#myFooter a:hover,
#myFooter a:focus {
    text-decoration: none;
    color: white;
}

#myFooter .social-networks {
    text-align: center;
    padding-top: 30px;
    padding-bottom: 16px;
}

#myFooter .social-networks a {
    font-size: 32px;
    color: #f9f9f9;
    padding: 10px;
    transition: 0.2s;
}

#myFooter .social-networks a:hover {
    text-decoration: none;
}

#myFooter .facebook:hover {
    color: #0077e2;
}

#myFooter .google:hover {
    color: #ef1a1a;
}

#myFooter .twitter:hover {
    color: #00aced;
}

#myFooter .btn {
    color: white;
    background-color: #d84b6b;
    border-radius: 20px;
    border: none;
    width: 150px;
    display: block;
    margin: 0 auto;
    margin-top: 10px;
    line-height: 25px;
}

@media screen and (max-width: 1200px) {


    .navbar-nav > a,
    .navbar-nav > .nav-item,
    .nav-link,
    .nav-link > svg {
        font-size: 16px !important;
        text-align: center;
    }



  .page-header{
    font-size: 2em;
    padding: 0px 0 25px;
  }
}


@media screen and (max-width: 1024px) {


  .jumbotron{
    padding: 5rem 1rem !important;
    margin-bottom: 0 !important;
    min-height: 500px;
    border-bottom: 3px ridge #B21606;
    background-size: cover;
  }


  .carousel-caption > p{
    font-size: 1.2em;
  }

  .carousel-caption > h1{
    padding-bottom: 200px;
    font-size: 2.2em;
  }

  h1{
    font-size: 1em;
  }

  p{
    font-size: 1em;
  }

  .mobileRemove
  {
    display: none;
  }

  .mobileExpand
  {
    width: 20%;
  }


  .page-header{
    font-size: 1.9em;
    padding: 0px 0 20px;
  }

  .car-title-row{
    margin-top: 20px;
  }

  .button-text
  {
    display: none;
  }

}


@media screen and (max-width: 767px) {
    #myFooter {
        text-align: center;
    }

    .jumbotron{
      padding: 3rem 1rem !important;
      margin-bottom: 0 !important;
      min-height: 300px;
      border-bottom: 3px ridge #B21606;
      background-size: cover;
    }
  
  
    .carousel-caption > p{
      font-size: 1.2em;
    }
  
    .carousel-caption > h1{
      padding-bottom: 100px;
      font-size: 1.8em;
    }

    
    .car-modal-details-value
    {
      text-transform: capitalize;
      display: inline-block;
      font-size: 0.8em;
    }

    .header-icon-td{
       float: right;
       margin-top: 0;
    }

    .call-us-container{
      display: none;
    }

    .header-icon-table{
      min-width: 130px;
    }

    .navbar-nav > a,
    .navbar-nav > .nav-item,
    .nav-link,
    .nav-link > svg {
    text-align: center;
    font-size: 14px !important;
    }

    .nav-link{

      padding: 0rem 1rem 0rem 1rem !important;
    }

    .page-header{
      font-size: 1.8em;
      padding: 0px 0 15px;
    }


}

#myFooter{
   flex: 0 0 auto;
   -webkit-flex: 0 0 auto;
}
